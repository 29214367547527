import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import DetailsModal from "@/components/DetailsModal";
import File from "../../components/File";
import Vue from "vue";

export default {
  mixins: [withoutWatchersMixin],
  name: "Users",
  components: {
    Multiselect,
    Pagination,
    HasError,
    DetailsModal,
    File,
  },
  data() {
    return {
      users: [],
      sectors: [],
      roles: [],
      searchDropdown: false,
      deleteId: null,
      item: null,
      showPass: false,
      showPass2: false,
      selectedSector: null,
      selectedRole: { id: 4, name: "user" },
      form: new Form({
        first_name: "",
        last_name: "",
        email: "",
        sector_id: null,
        remote: false,
        role_id: 4,
      }),
      form_import: new Form({
        file: null,
      }),
      fileRecordImport: null,
      currentPage: 1,
      loading: false,
      deleting: false,
      modalTitle: i18n.tc("equipment.add_user"),
      filters: {
        term: "",
        sector: 0,
      },
    };
  },
  methods: {
    setStatusClass(i) {
      switch (i) {
        case "user":
          return "waiting";
        case "support":
          return "in-progress";
        case "super-admin":
          return "rejected";
        case "hr":
          return "accepted";
      }
    },
    formatRole(i) {
      return i.replace("-", " ");
    },
    getAllSectors() {
      API_URL.get("sector").then((response) => {
        this.sectors = response.data.data;
      });
    },
    getAllRoles() {
      API_URL.get("roles").then((response) => {
        this.roles = response.data.data;
      });
    },
    details(i) {
      this.item = i;
      this.$bvModal.show("details");
    },
    edit(item) {
      this.modalTitle = i18n.tc("equipment.edit_user");
      this.item = item;
      this.form.id = item.id;
      this.form.first_name = item.first_name;
      this.form.last_name = item.last_name;
      this.form.email = item.email;
      this.form.remote = item.remote;
      this.form.password = item.password;
      this.form.password_confirmation = item.password_confirmation;
      this.selectedRole = { id: item.role[0].id, name: item.role[0].name };
      this.form.sector_id = item.sector.id;
      this.form.role_id = item.role[0].id;
      this.selectedSector = item.sector;
      this.$bvModal.show("modal-1");
    },
    deleteU(item) {
      this.deleteId = item.id;
      this.$bvModal.show("deleteEquipment");
    },
    deleteUser() {
      this.deleting = true;
      API_URL.delete(`users/${this.deleteId}`).then(() => {
        this.getAllUsers();
        this.currentPage = 1;
        this.$toast.success(i18n.tc("notifications.success"));
        this.closeModal();
        this.deleting = false;
      });
    },
    filesSelectedImport: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      validFileRecords.forEach((currFile) => {
        this.form_import.file = currFile.file;
      });
    },
    onBeforeDeleteImport: function () {
      this.fileRecordImport = null;
      this.form_import.file = null;
    },
    async importExcel() {
      await this.form_import
        .post("users/users-import")
        .then(() => {
          this.getAllUsers();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-import");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            Vue.prototype.$toast.error(errorMessage);
          }
        });
    },
    closeImport() {
      this.form_import.file = null;
      this.fileRecordImport = null;
    },
    closeModal() {
      this.form.reset();
      this.form.errors.errors = {};
      this.selectedRole = { id: 4, name: "user" };
      this.selectedSector = null;
      this.$bvModal.hide("deleteEquipment");
      this.modalTitle = i18n.tc("equipment.add_user");
    },
    submitForm() {
      if (this.item === null) {
        this.form
          .post("users")
          .then(() => {
            this.getAllUsers();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form
          .put(`users/edit/${this.item.id}`)
          .then(() => {
            this.getAllUsers();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllCategories() {
      API_URL.get("category").then((response) => {
        this.equipmentCategories = response.data;
      });
    },
    getAllEquipmentTypes() {
      API_URL.get("equipment-type").then((response) => {
        this.equipmentTypes = response.data;
      });
    },
    getSubcategories() {
      API_URL.get("subcategory").then((response) => {
        this.subcategories = response.data;
      });
    },
    getAllUsers() {
      this.loading = true;
      API_URL.get("users", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.users = response.data;
        this.loading = false;
      });
    },
    closeSearchDropdown() {
      this.searchDropdown = false;
    },
  },
  computed: {
    sectorOptions() {
      return [{ id: 0, name: "Svi sektori" }, ...this.sectors];
    },
    resultsCount() {
      return this.users?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getAllUsers();
      }, 250),
    },
    currentPage() {
      this.getAllUsers();
    },
  },
  mounted() {
    this.getAllUsers();
    this.getAllSectors();
    this.getAllRoles();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Korisnici",
    });
  },
};
